<template>
  <div>
    <q-form ref="editForm">
      <!-- 엑셀업로드 -->
      <c-card title="LBL0002521" class="cardClassDetailForm">
        <template slot="card-button">
          <q-btn-group outline >
            <c-btn 
              v-if="editable" 
              label="LBLAPPLY" 
              icon="save"
              @btnClicked="acceptData" />
          </q-btn-group>
        </template>
        <template slot="card-detail">
          <div class="col-xs-12 col-sm-3 col-md-3 col-lg-3">
            <!-- 담당부서/이름/일자 -->
            <c-text
              :editable="editable"
              :disabled="true"
              label="LBL0002522"
              name="request"
              v-model="request">
            </c-text>
          </div>
          <div class="col-xs-12 col-sm-3 col-md-3 col-lg-3">
            <c-text
              :disabled="true"
              :editable="editable"
              label="제목"
              v-model="regData.uploadTitle">
            </c-text>
          </div>
        </template>
      </c-card>
    </q-form>
    <c-excel-upload 
      :editable="editable"
      :excelUploadInfo="excelUploadInfo"
      tabHeight="660px"
      gridHeight="630px"
    />
  </div>
</template>

<script>
export default {
  name: 'checkup-result-excel-upload',
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          plantCd: '',
        }
      },
    },
  },
  data() {
    return {
      excelUploadInfo: {
        rowKeys: ['heaCheckupResultId'], // 데이터들의 키 속성값
        taskClassCd: 'HEA_CHECK_RESULT_UPLOAD', // 엑셀업로드 양식 구분 값
        sheetNum: 0,
        columns: [],
        data: [],
      },
      regData: {
        regDeptCd: '',
        uploadTitle: '',
        uploadDate: '',
      },
      changeItem: {
        plant: '',
        process: '',
        vendor: '',
      },
      editable: true,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    request() {
      return this.$store.getters.user.deptName + ' / ' + this.$store.getters.user.userName + ' / '  + this.$comm.getToday()
    },
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      // code 
      this.setHeader();
      this.regData.uploadTitle = `${this.$comm.getTodayDateTime()} 검진결과 업로드`
    // list setting
    },
    setHeader() {
        this.excelUploadInfo.columns = [
          [
            {
              name: 'checkTypeName',
              field: 'checkTypeName',
              label: '구분(선택만 가능)',
              align: 'center',
              style: 'width:100px',
              type: "select",
              comboItems: [
                { code: '일반', codeName: '일반' },
                { code: '특수', codeName: '특수' },
                { code: '배치전', codeName: '배치전' },
                { code: '배치후', codeName: '배치후' },
                { code: '일반과특수', codeName: '일반과특수' },
              ],
              sortable: false,
            },
            {
              name: 'deptName',
              field: 'deptName',
              label: '부서',
              align: 'center',
              style: 'width:100px',
              type: "readonly",
              sortable: false,
            },
            {
              name: 'empNo',
              field: 'empNo',
              label: '사번',
              align: 'center',
              style: 'width:100px',
              type: "readonly",
              sortable: false,
            },
            {
              name: 'userName',
              field: 'userName',
              label: '이름',
              align: 'center',
              style: 'width:100px',
              type: "readonly",
              sortable: false,
            },
            {
              name: 'checkupDate',
              field: 'checkupDate',
              label: '검진일자(YYYY-MM-DD) 형식으로 넣어주세요.',
              align: 'center',
              style: 'width:100px',
              type: "date",
              sortable: false,
            },
          ]
        ]
    },
    acceptData() {
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          if (this.excelUploadInfo.data) {
            let dataCntCheck = true;
            let errorDataCheck = false;
            if (!this.excelUploadInfo.data[0] || this.excelUploadInfo.data[0].length === 0) {
              dataCntCheck = false;
            }
            if (this.$_.findIndex(this.excelUploadInfo.data[0], (item) => { return item.error_message }) > -1) {
              errorDataCheck = true
            }
            if (!dataCntCheck) {
              window.getApp.$emit('ALERT', {
                title: 'LBLGUIDE', // 안내
                // 업로드할 데이터가 없는 Sheet가 있습니다.\n\r데이터 확인 또는 엑셀을 업로드 하였는지 확인바랍니다.
                message: 'MSG0000750', // 업로드할 데이터가 없는 Sheet가 있습니다.\n\r데이터 확인 또는 엑셀을 업로드 하였는지 확인바랍니다.
                type: 'warning', // success / info / warning / error
              });
            } else {
              if (errorDataCheck) {
                window.getApp.$emit('CONFIRM', {
                  title: 'LBLCONFIRM',
                  // 에러가 있는 데이터가 존재합니다.\n\r에러 있는 데이터를 제외한 나머지 데이터를 적용하시겠습니까?
                  message: 'MSG0000751',
                  // TODO : 필요시 추가하세요.
                  type: 'info', // success / info / warning / error
                  // 확인 callback 함수  
                  confirmCallback: () => {
                    this.$emit('closePopup', this.excelUploadInfo.data[0], this.regData);
                  },
                  // 취소 callback 함수
                  cancelCallback: () => {
                  },
                });
              } else {
                this.$emit('closePopup', this.excelUploadInfo.data[0], this.regData);
              }
            }
          }
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      })
    }
  }
};
</script>
